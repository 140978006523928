import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import SettingsPage from './views/settingsPage';
import LoginPage from './views/loginPage';
import ListPage from './views/listPage';
import FormPage from './views/formPage';
import EbayPage from './views/ebayPage';
import EbayCreateOffer from './views/ebayCreateOffer';
import Order from './views/order';
//const history = createHistory();


let formPages = [

    {
        title: 'Newsletter',
        subtitle: 'Configure newsletter',
        link: '/newsletters',
        collection: 'newsletters',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'html',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'list',
                name: 'products',
                width: {
                    desktop: 4,
                    mobile: 12
                },

                fields: [
                    {
                        type: 'image',
                        name: 'Image',
                        width: {
                            desktop: 2,
                            mobile: 2
                        }
                    },
                    {
                        type: 'text',
                        name: 'Alias',
                        width: {
                            desktop: 4,
                            mobile: 4
                        }
                    },
                    {
                        type: 'text',
                        name: 'Name.de',
                        width: {
                            desktop: 4,
                            mobile: 4
                        }
                    },
                ],

            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                id: '342yh43hi234',
                type: 'search',
                label: 'Search products',
                collection: 'products',
                searchFields: ['Alias', 'Name.de'],
                fields: [
                    {
                        type: 'image',
                        name: 'Image'
                    },
                    {
                        type: 'text',
                        name: 'Alias'
                    },
                    {
                        type: 'text',
                        name: 'Name.de'
                    }
                ],
                limit: 10,
                onSelect: (scope, value) => {
                    console.log(value);
                    let fields = scope.state.fields;
                    let initialValues = scope.state.initialValues;
                    console.log(initialValues['products']);
                    if (!initialValues['products'])
                        initialValues['products'] = [];
                    initialValues['products'].push(value);
                    for (let i = 0; i < fields.length; i++) {
                        fields[i]._temp = new Date();

                    }
                    scope.setState({ initialValues: initialValues, fields: [] }, () => {
                        scope.forceUpdate();
                        scope.setState({ fields: fields });
                    });


                },
                width: {
                    desktop: 4,
                    mobile: 12
                }
            }, {
                type: 'spacer'
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Narudžba',
        subtitle: 'Pregled narudžbe',
        link: '/orders',
        collection: 'orders',
        multilang: false,
        fields: [
            {
                type: 'label',
                name: 'orderNumber',
                beforeText: 'Broj narudžbe: ',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            }, {
                type: 'label',
                name: 'Status',
                beforeText: 'Status: ',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'DeliveryMethod.name',
                beforeText: 'Dostava: ',

                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'BillingMethod',
                beforeText: 'Način plaćanja: ',
                type: 'label',

                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'subtotal',
                beforeText: 'Iznos bez PDV-a: ',
                afterText: ' RSD',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'tax',
                beforeText: 'Iznos PDV-a ',
                afterText: ' RSD',
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'label',
                name: 'total',
                beforeText: 'Iznos sa PDV: ',
                afterText: ' RSD',
                width: {
                    desktop: 4,
                    mobile: 12
                }

            },
            {
                type: 'spacer'
            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'title',
                text: 'Podešavanje fakture'
            },
            {
                name: 'FiscalNumber',
                label: 'Broj fiskalnog računa',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Charged',
                label: 'Datum fakturisanja',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Delivered',
                label: 'Datum isporuke',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'BillMaxDateToPay',
                label: 'Valuta plaćanja',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, {
                name: 'Warehouse',
                label: 'Skladište',
                type: 'select',
                values: [
                    {
                        name: '1 - MAGACIN',
                        value: '1 - MAGACIN'
                    }, {
                        name: '2 - VELEPRODAJA',
                        value: '2 - VELEPRODAJA'
                    }, {
                        name: '3 - MALOPRODAJA',
                        value: '3 - MALOPRODAJA'
                    },

                ],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.JIB',
                label: 'JIB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.PDV',
                label: 'PDV',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.MB',
                label: 'MB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.TaxPayer',
                label: 'Obavezik PDV-a',
                type: 'select',
                values: [
                    {
                        name: 'NE',
                        value: '0'
                    }, {
                        name: 'DA',
                        value: '1'
                    }
                ],
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },


            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'button',
                text: 'Prikaži narudžbenicu',
                width: {
                    desktop: 3,
                    mobile: 12
                },
                onClick: (scope) => {
                    window.location = 'https://admin.cerpromet.com/order/' + scope.state.initialValues._id;
                }

            },
            {
                type: 'spacer'
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Stranice',
        subtitle: 'Izmena stranice',
        link: '/pages',
        collection: 'pages',
        multilang: false,
        fields: [

            {
                name: 'title',
                label: 'Naslov',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

            {
                name: 'content',
                label: 'Tekst',
                type: 'html',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Proizvod',
        subtitle: 'Dodaj/Izmeni proizvod',
        link: '/products',
        collection: 'products',
        fields: [
            {
                name: 'Alias',
                label: 'Šifra proizvoda',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BarCode',
                label: 'Bar kod',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Proizvod je vidljiv u shopu',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'IsPopular',
                label: 'Preporucen proizvod',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'OnOffer',
                label: 'Na akciji',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'BestSeller',
                label: 'Najprodavaniji proizvod',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                "type": "spacer"
            },
            {
                name: 'startDate',
                label: "Akcija Od datuma",
                type: "date",
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'endDate',
                label: "Akcija Do datuma",
                type: "date",
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                "type": "spacer"
            },
            {
                name: 'Images',
                label: 'Slike',
                type: 'file-multiple',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }, {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Name',
                label: 'Naziv proizvoda',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Package',
                label: 'Ambalaza',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'OrderUnit',
                label: 'Jedinica mere',
                type: 'select',
                values: [
                    {
                        name: 'Komad',
                        value: 'komad'
                    }, {
                        name: 'Litar',
                        value: 'litar'
                    }, {
                        name: 'Kilogram',
                        value: 'kg'
                    },
                ],
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, {
                type: 'spacer',
            },
            {
                name: 'MinOrder',
                label: 'Minimalna narudzba (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevel',
                label: 'Na stanju',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevelAlert',
                label: 'Upozorenje za magacin (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'ShortDescription',
                label: 'Kratak opis',
                type: 'html',
                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                name: 'Description',
                label: 'Opis artikla',
                type: 'html',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            }/*, {
                name: 'SendNewsletter',
                label: 'Pošalji newsletter',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }*/

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'Breadcrumb',
                label: 'Category',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Breadcrumb
                            }
                        )
                    });
                },
                additionalAction: (scope, value) => {
                    let state = scope.state;
                    let categories = state.linkedCollection.categories;
                    let attributes = state.linkedCollection.attributes;

                    let fields = state.fields;
                    fields.push({
                        type: 'offset',
                        width: {
                            desktop: 12,
                            mobile: 12
                        }

                    });

                    for (let i = 0; i < fields.length; i++) {
                        if (fields[i].name && fields[i].name.indexOf('attributes.') !== -1) {
                            delete fields[i];
                        }
                    }

                    for (let i = 0; i < categories.length; i++) {
                        if (categories[i].Breadcrumb == value) {
                            console.log(categories[i].Breadcrumb, value);
                            for (let j = 0; j < attributes.length; j++) {
                                console.log(categories[i], attributes[j]);
                                if (categories[i].attributes.includes(attributes[j].Alias)) {
                                    console.log(attributes[j].alias);
                                    fields.push({
                                        label: attributes[j].Name,
                                        name: 'attributes.' + attributes[j].Alias,
                                        values: attributes[j].Values.map((attr, idx1) => {
                                            return { name: attr, value: attr }
                                        }),
                                        type: 'select',
                                        width: {
                                            desktop: 3, mobile: 12
                                        }
                                    })
                                }
                            }

                        }
                    }

                    scope.setState({ fields: fields }, () => scope.forceUpdate());

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                linkedCollection: 'attributes',
                type: 'hidden',
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                values: []

            }, {
                label: 'Proizvodjac',
                linkedCollection: 'manufacturers',
                name: 'Manufacturer',
                type: 'select',
                renderItems: (collection) => {
                    if (collection.length) {
                        return collection[0].Values.map((item) => {
                            return (
                                {
                                    name: item,
                                    value: item
                                }
                            )
                        });
                    } else {
                        return [];
                    }
                },
                values: [],
                additionalAction: () => {

                },

                width: {
                    desktop: 3,
                    mobile: 12
                }


            }

        ],



    },

    {
        title: 'Korisnik',
        subtitle: 'Dodaj/Izmeni korisnika',
        link: '/users',
        collection: 'users',
        multilang: false,
        fields: [
            {
                name: 'User.Name',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'User.EMail',
                label: 'Email',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: 'select',
                values: [{ name: 'Fizičko lice', value: 'PhysicalPerson' }, { name: 'Pravno lice', value: 'LegalPerson' }],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },



            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'EmailVerified',
                label: 'Verifikovan email',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'IsSalesManager',
                label: 'Komercijalista',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'SubscribeToNewsletter',
                label: 'Prijavljen na newsletter',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.JIB',
                label: 'JIB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.PDV',
                label: 'PDV',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.MB',
                label: 'MB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.TaxPayer',
                label: 'Obavezik PDV-a',
                type: 'select',
                values: [
                    {
                        name: 'NE',
                        value: '0'
                    }, {
                        name: 'DA',
                        value: '1'
                    }
                ],
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },



            {
                type: 'spacer'
            },




            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }, {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },


        ],
        linkedFields: []

    }, {
        title: 'Kategorija',
        subtitle: 'Dodaj/Izmeni kategoriju',
        link: '/categories',
        collection: 'categories',

        fields: [
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Vidljiva',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'title',
                text: 'Banner na kategorijama 1'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Banners[8].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'title',
                text: 'Banner na kategorijama 2'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Banners[9].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'attributes',
                name: 'attributes',
                label: 'Atributi',
                type: 'select',
                multiselect: true,
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Alias
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                linkedCollection: 'categories',
                name: 'ParentCategory',
                label: 'Kategorija',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }

        ]

    },
    {
        title: 'Atribut',
        subtitle: 'Dodaj/Izmeni atribut',
        link: '/attributes',
        collection: 'attributes',

        fields: [
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Values',
                label: 'Vrednosti',
                type: 'tags',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Novosti',
        subtitle: 'Dodaj/Izmeni novost',
        link: '/news',
        collection: 'news',

        fields: [
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Title',
                label: 'Naslov',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'ShortDescription',
                label: 'Kratak opis',
                type: 'text-multiline',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Content',
                label: 'Sadrzaj',
                type: 'html',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Aktivna',
                type: 'checkbox',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Proizvodjaci',
        subtitle: 'Dodaj proizvodjace',
        link: '/manufacturers',
        collection: 'manufacturers',

        fields: [
            {
                name: 'Values',
                label: 'Proizvodjaci',
                type: 'tags',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Dostava',
        subtitle: 'Dodaj/Izmeni dostavu',
        link: '/delivery-methods',
        collection: 'deliveryMethods',

        fields: [
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'name',
                label: 'Ime',
                type: 'text',

                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'price',
                label: 'Cena',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'url',
                label: 'Link',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            }
        ],
        linkedFields: []
    },


    {
        title: 'Settings',
        subtitle: 'Change settings',
        link: '/settings',
        collection: 'settings',
        multilang: false,
        fields: [
            {
                type: 'title',
                text: 'Slider na početnoj'
            },
            {
                name: 'Slides',
                label: 'Slider slike',
                type: 'file-multiple',

                width: {
                    desktop: 6,
                    mobile: 12,
    
                }
            },
            {
                type: 'title',
                text: 'Baner na početnoj #1'
            },
            {
                name: 'Banners[0].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Banners[0].Link',
                label: 'Link',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'title',
                text: 'Baner na početnoj #2'
            },
            {
                name: 'Banners[1].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Banners[1].Link',
                label: 'Link',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'title',
                text: 'Baner na početnoj #3'
            },
            {
                name: 'Banners[2].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'title',
                text: 'Baner na početnoj #4'
            },
            {
                name: 'Banners[3].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Banners[3].Link',
                label: 'Link',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'title',
                text: 'Baner na početnoj #5'
            },
            {
                name: 'Banners[4].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Banners[4].Link',
                label: 'Link',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'title',
                text: 'Photo O nama'
            },
            {
                name: 'Banners[6].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'Banners[6].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'title',
                text: 'Photo Dostava'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Banners[7].Image',
                label: 'Slika',
                type: 'file',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'FooterText',
                label: 'Footer Text',
                type: 'text-multiline',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'Breadcrumb',
                label: 'Category',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Breadcrumb
                            }
                        )
                    });
                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                linkedCollection: 'attributes',
                type: 'hidden',
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                values: []

            },
        ],

    },
    {
        title: 'Pop Up',
        subtitle: 'Change Pop Up',
        link: '/popup',
        collection: 'popup',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Subtitle',
                label: 'Subtitle',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'Background',
                label: 'Background',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },

]


let listPages = [
    {
        title: 'Stranice',
        subtitle: 'Lista stranica',
        link: '/pages',
        collection: 'pages',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: "alias",
                label: "Alias",
                type: "text",
            }, {
                name: "title",
                label: "Naslov",
                type: "text",
            },
        ],

    },
    {
        title: 'Atributi',
        subtitle: 'Lista atributa',
        link: '/attributes',
        collection: 'attributes',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Name',
                label: "Naziv",

                type: "text"
            },
        ],
    }, {
        title: 'Novosti',
        subtitle: 'Lista novosti',
        link: '/news',
        haveImage: true,
        collection: 'news',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: 'Image',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Title',
                label: "Naslov",

                type: "text"
            },
        ],
        sortOptions: [
            {
                name: 'Po naslovu',
                field: 'Title',
                sort: 1
            },
        ]
    }, {
        title: 'Proizvodi',
        subtitle: 'Lista proizvoda',
        link: '/products',
        haveImage: true,
        collection: 'products',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Images[0]',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Šifra",
                type: "text",
            },
            {
                name: 'Name',
                label: "Naziv",

                type: "text"
            },
            {
                name: 'Package',
                label: "Ambalaza",

                type: "text"
            },
            {
                name: "mpc",
                label: "MPC",

                type: "price",
                allowEdit: true
            },
            {
                name: "vpc",
                label: "VPC",

                type: "price",
                allowEdit: true
            },
            {
                name: "mpcDiscount",
                label: "MPC Popust [%]",

                type: "number",
                allowEdit: true
            },
            {
                name: "vpcDiscount",
                label: "VPC Popust [%]",

                type: "number",
                allowEdit: true
            },
            {
                name: "actionDuration",
                label: "Rok trajanja akcije",

                type: "number",
                allowEdit: true
            },
            {
                name: "StockLevel",
                label: "Stanje",

                type: "number",
                allowEdit: true

            }
        ],

    },
    {
        title: 'Korisnici',
        subtitle: 'Lista korisnika',
        link: '/users',
        haveImage: false,
        collection: 'users',
        searchFields: ['Alias', 'User.Name', 'User.EMail'],
        itemFields: [
            /*{
                name: '_id',
                type: "text"
            }*/,
            {
                name: 'User.Name',
                label: 'Name',
                type: "text"
            },
            {
                name: 'User.EMail',
                label: 'EMail',
                type: "text"
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: "text"
            },

            {
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: "text"
            },

        ],
    }, {
        title: 'Kategorije',
        subtitle: 'Lista kategorija',
        link: '/categories',
        collection: 'categories',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Alias',
                type: "text",
                label: "Alias"
            },
            {
                name: 'Breadcrumb',
                type: "text",
                label: "Breadcrumb"

            },
        ],

    }, {
        title: 'Dostave',
        subtitle: 'Lista dostava',
        link: '/delivery-methods',
        haveImage: false,
        collection: 'deliveryMethods',
        searchFields: ['alias', 'name'],
        itemFields: [
            {
                name: 'alias',
                type: "text",
                label: "Alias"

            },
            {
                name: 'name',
                type: "text",
                label: "Name"

            },
            {
                name: 'price',
                type: "price",
                label: "Price"

            },
        ],
    },
    {
        title: 'Slider na početnoj',
        subtitle: 'Lista slajdova',
        link: '/slides',
        haveImage: true,
        collection: 'slides',
        searchFields: [],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            },
        ],
    },
    {
        title: 'Baneri',
        subtitle: 'Lista banera',
        link: '/banners',
        haveImage: true,
        collection: 'banners',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            }, {
                name: 'Title',
                type: "text",
                label: 'Title'

            },
            {
                name: 'Subtitle',
                type: "text",
                label: 'Subtitle'

            },
        ],
    },
    {
        title: 'Newsletters',
        subtitle: 'List of newsletters',
        link: '/newsletters',
        haveImage: true,
        collection: 'newsletters',
        searchFields: ['Title', 'Text'],
        itemFields: [
            {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Popup',
        subtitle: 'List of popup',
        link: '/popup',
        haveImage: true,
        collection: 'popup',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            }, {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'Kontakti',
        subtitle: 'Lista kontakata',
        link: '/contacts',
        collection: 'contacts',
        disableDelete: true,
        disableEdit: true,
        searchFields: ['EMail', 'Name'],
        itemFields: [
            {
                name: 'Name',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'EMail',
                label: 'EMail',

                type: "text"
            }, {
                name: 'Subject',
                label: 'Naslov',

                type: "text"
            }, {
                name: 'Message',
                label: 'Poruka',

                type: "text"
            },

        ],
    },
    {
        title: 'Narudzbe',
        subtitle: 'Lista narudzbi',
        link: '/orders',
        collection: 'orders',
        disableDelete: true,
        searchFields: ['PayPalTransaction.transactions.invoice_number', 'BillingAddress.FirstName', 'BillingAddress.LastName', 'BillingAddress.Company'],
        itemFields: [
            {
                name: 'orderNumber',
                label: 'Broj narudžbe',
                type: "text"
            },
            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',

                type: "text"
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',

                type: "text"
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',

                type: "text"
            },
            {
                name: 'DeliveryMethod.name',
                label: 'Dostava',

                type: "text"
            },
            {
                name: 'BillingMethod',
                label: 'Način plaćanja',

                type: "text"
            },

            {
                name: 'total',
                label: 'Ukupno',
                type: "price"
            },
            {
                name: 'Status',
                label: 'Status',
                type: "text"
            },

            {
                name: 'orderTime',
                label: 'Datum',

                type: "datetime",
                format: "DD.MM.YYYY  HH:mm"
            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'User orders',
        subtitle: 'List of orders',
        link: '/user-orders/:uid',
        collection: 'orders',
        disableDelete: true,
        searchFields: ['PayPalTransaction.transactions.invoice_number', 'BillingAddress.FirstName', 'BillingAddress.LastName', 'BillingAddress.Company'],
        itemFields: [
            {
                name: 'order.PayPalTransaction.transactions[0].invoice_number',
                label: 'Invoice Number',
                type: "text"
            },
            {
                name: 'BillingAddress.FirstName',
                label: 'FirstName',
                type: "text"
            },
            {
                name: 'BillingAddress.LastName',
                label: 'LastName',

                type: "text"
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',

                type: "text"
            },
            {
                name: 'BillingAddress.Country',
                label: 'Country',

                type: "text"
            },
            {
                name: 'Note',
                label: 'Note',

                type: "text"
            },
            {
                name: 'order.DeliveryMethod.name.de',
                label: 'Delivery Method',

                type: "text"
            },
            {
                name: 'order.BillingMethod',
                label: 'Billing Method',

                type: "text"
            },

            {
                name: 'order.total',
                label: 'Total',

                type: "price"
            },
            {
                name: 'Status',
                label: 'Status',

                type: "text"
            },
            {
                name: 'order.PayPalTransaction.create_time',
                label: 'Time',

                type: "datetime",
                format: "DD.MM.YYYY  HH:mm"
            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },


];


class Routes extends Component {

    componentDidMount() {
        /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */
    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/order/:id"
                            exact
                            render={(...renderProps) => (
                                <Order {...renderProps} {...this.props} />
                            )}
                        />

                        {
                            listPages.map((page, idx) => {
                                return (
                                    <Route
                                        path={page.link}
                                        exact
                                        render={(...renderProps) => (
                                            <ListPage {...page} {...renderProps} {...this.props} />
                                        )}
                                    />
                                )

                            })

                        }

                        {
                            formPages.map((page, idx) => {
                                return (
                                    <Route
                                        path={`${page.link}/:id`}
                                        exact
                                        render={(...renderProps) => (
                                            <FormPage {...page} {...renderProps} {...this.props} />
                                        )}
                                    />
                                )

                            })
                        }


                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;