import React, { Component } from 'react';
import logo from '../assets/images/logo.png';
import autoin from '../assets/svg/logo.svg';


import {
    Container,
    Row,
    Col
} from 'reactstrap';

import moment from 'moment';


function formatPrice(price, dec=2) {
    return parseFloat(price).toFixed(dec)
}


export default class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }




    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on("adminFetchOne", (data) => {
            console.log(data);
            this.setState(data)

        });

        this.props.socketIOClient.emit("adminFetchOne", { query: { _id: this.props[0].match.params.id }, collection: 'orders' });

    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }



    render() {

        let total = this.state.total;

        let parts = [0,0,0,0];




        console.log(this.state)



        return (

            <div className="order-account">
                <Container>

                    <div className="left">
                        doo.Cerpromet - Skenderbegova 27, 11000 Beograd, Srbija<br />
                        Tel./Fax: 381 11 218 3391<br/>
                        Web: www.cerpromet.com
                    </div>
                    <div className="right">
                        <img src={autoin} className="img-fluid logo" />
                    </div>
                    <div className="info">
                        <h1>Cerpromet DOO.</h1>
                        <div className="title">

                            <h2>PIB:</h2>
                            <h2>MB:</h2>
                            <h2>Ž.R:</h2>
                        </div>
                        <div className="datas">
                            <h2>100046775</h2>
                            <h2>07479034</h2>
                            <h2>Banca Intesa 160-6000000012156-53</h2>
                        </div>

                    </div>
             
                    <div className="komitet">
                        <h3>Komitent:</h3>
                        {this.state.BillingAddress ? <div className="box">
                            <h6>{this.state.BillingAddress.Company ? this.state.BillingAddress.Company : this.state.BillingAddress.FirstName + ' ' + this.state.BillingAddress.LastName} .</h6>
                            <h6>{this.state.BillingAddress.Street}</h6>
                            <h6>{this.state.BillingAddress.City} {this.state.BillingAddress.Zipcode}</h6>
                            <h6>{this.state.BillingAddress.Phone}</h6>
                            <h6>{this.state.BillingAddress.EMail}</h6>

                            {/*<h6>JIB: {this.state.BillingAddress.JIB}</h6>
                            <h6>PDV br: {this.state.BillingAddress.PDV}</h6>
                            <h6>MB: {this.state.BillingAddress.MB}</h6>
                            */}
                            <h6>{this.state.BillingAddress.TaxPayer == '1' ? 'Obaveznik PDV-a' : null}</h6>
                        </div>
                        : null}
                        <h4>Strana 1</h4>
                    </div>
                    <div className="naslov">
                    {this.state.BillingAddress && this.state.BillingMethod == 'kreditna-kartica' ? 
                    null :
                    <h1>NARUDZBENICA - OTPREMNICA - {this.state.orderNumber}/{this.state.Charged && this.state.Charged.split('.').pop().slice(-2)} - {this.state.Warehouse ? this.state.Warehouse.split('-')[0].trim() : ''}</h1>
                    }
                       
                    </div>
                    <div className="heidingTable">
                        <div className="col1">
                            <h3>PO FISKALNOM RAČUNU BR : {this.state.FiscalNumber}</h3>
                            <h3>SKLADIŠTE : {this.state.Warehouse} </h3>
                            <h3> Datum fakturisanja: {this.state.Charged} </h3>
                        </div>
                        <div className="col2">
                            <h3>Mesto izdavanja fakture: BEOGRAD</h3>
                            <h3>Datum isporuke: {this.state.Delivered}</h3>
                        </div>
                        <div className="col3">
                            <h3>
                                VALUTA PLAĆANJA: {this.state.BillMaxDateToPay}
                            </h3>
                        </div>
                        <div className="col4">
                            <h3>PIB: 100046775</h3>
                            <h3>MB: 07479034</h3>
                            <h3>Ž.R: Banca Intesa 160-6000000012156-53</h3>
                        </div>
                    </div>
                    <table className="nasloviTabele">
                        <tr>
                            <td>Rbr</td>
                            <td>Naziv i broj artikla</td>
                            <td>Pakovanje</td>
                            <td>JM.</td>
                            <td>Količina</td>
                            <td>Cena</td>
                            <td>Iznos</td>
                            <td>Rab%</td>
                            <td>Iznos <br /> bez PDV</td>
                            <td>P%</td>
                            <td>PDV</td>
                            <td>Cena <br /> sa PDV</td>
                            <td>Iznos <br /> sa PDV</td>

                        </tr>

                        {
                            this.state.products ? this.state.products.map((item, idx) => {

                                return (
                                    <tr className="white" key={idx}>

                                        <td >{idx + 1}</td>
                                        <td>{item.Alias + ' - ' +item.Name}</td>
                                        <td>{item.Package}</td>
                                        <td> {item.OrderUnit}</td>
                                        <td>{item.cartQuantity}</td>
                                        <td>{ formatPrice(item.price) }</td>
                                        <td>{ formatPrice(item.cartQuantity * item.price) }</td>
                                        <td> { formatPrice(item.mpcDiscount || item.vpcDiscount ? item.mpcDiscount || item.vpcDiscount : 0, 1)  } </td>
                                        <td>{ formatPrice(item.price) }</td>
                                        <td>20</td>
                                        <td>{ formatPrice( (item.price * 1.2) - item.price)}</td>
                                        <td>{ formatPrice(item.price * 1.2, 3)}</td>
                                        <td>{ formatPrice(item.price * item.cartQuantity * 1.2)}</td>


                                    </tr>

                                )
                            })

                                : null
                        }
             
                    </table>
                    <div className="underTableLeft">
                    </div>
                    <div className="underTableRigth">
                        <div className="leftUnderTable">
                            <h6>Ukupno:</h6>
                            {/*<h6>Iznos rabata:</h6>*/}
                        </div>
                        <div className="rightUnderTable">
                            <h6>{ formatPrice(this.state.itemsTotal) }</h6>
                            {/*<h6>0.00</h6>*/}

                        </div>
                        <div className="leftUnderTable">
                            <h6><span>Iznos bez PDV-a:</span></h6>
                            <h6>Iznos PDV-a:</h6>
                        </div>
                        <div className="rightUnderTable">
                            <h6><span>{formatPrice(this.state.subtotal)}</span></h6>
                            <h6>{formatPrice(this.state.tax) }</h6>

                        </div>
                        <div className="leftUnderTable noBorder">

                            <h6><span>Iznos sa PDV-a:</span></h6>
                        </div>
                        <div className="rightUnderTable noBorder">
                            <h6><span>{ formatPrice( this.state.total - (this.state.DeliveryMethod  && this.state.DeliveryMethod.price  ? this.state.DeliveryMethod.price : 0 ) )}</span></h6>


                        </div>
                    </div>

                    <div className="potpis">
                        <div className="col1">
                            <div className="cjena">
                                <h5>ZA UPLATU: &ensp;&ensp;&ensp; { formatPrice( this.state.total - (this.state.DeliveryMethod  && this.state.DeliveryMethod.price  ? this.state.DeliveryMethod.price : 0 ) ) } RSD</h5>

                            </div>
                            <h6>Robu izdao:</h6>
                            <hr></hr>
                        </div>
                        <div className="col2">
                            <div className="cjena">

                            </div>
                            <h6>Pakovao :</h6>
                            <hr></hr>

                        </div>
                        <div className="col3">

                            <h6>Robu preuzeo :</h6>
                            <hr></hr>

                        </div>
                    </div>
                </Container>


            </div>
        )
    }

}

