import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';

import Isvg from 'react-inlinesvg';
import ebay_icon from '../assets/images/ebay.png';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import { CSVLink, CSVDownload } from "react-csv";

import ReactPaginate from 'react-paginate';

import image from '../assets/images/no-image.jpg';
import Select from '../components/forms/fields/select';

import moment from 'moment';

const itemFields = [
    {
        name: 'Alias',
        type: "text"
    },
    {
        name: 'User.Name',
        type: "text"
    },
    {
        name: 'User.EMail',
        type: "text"
    },
    {
        name: 'BillingAddress.Country',
        type: "text"
    },

    {
        name: 'CustomerGroup',
        type: "text"
    }
]



class ListPage extends Component {
    constructor(props) {
        super(props);

        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.handleDeletePrompt = this.handleDeletePrompt.bind(this);
        this.changePage = this.changePage.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.downloadInfo = this.downloadInfo.bind(this);

        this.state = {
            results: [],
            sort: null,
            page: 0,
            imageErrors: {}
        };
    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props[0].location.pathname !== nextProps[0].location.pathname) {
            this.setState({
                results: [],
                sort: null,
                page: 0

            }, () => {
                this.fetchItems();
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }


    fetchItems() {
        let obj = {
            collection: this.props.collection,
            query: {},
            limit: 20,
            skip: this.state.page * 20,
            sort: this.state.sort ? this.state.sort : (this.props[0].location.pathname == '/products') ? { Position: 1 } : {},
            search: this.state.search ? { fields: this.props.searchFields, value: this.state.search } : null,
        };


        if (this.state.showOnlyLowStockArticles) {
            obj.query.StockLevel = { $lte: 5 };
        }

        if (this.state.showOnlySubscribedUsers){
            obj.query.SubscribeToNewsletter = true;
        }

        if (this.props[0].match.params.uid && this.props[0].location.pathname.indexOf('user-orders') !== -1) {
            obj.query.uid = this.props[0].match.params.uid;
        }

        if (this.props[0].match.params.uid && this.props[0].location.pathname.indexOf('priceList') !== -1) {
            obj.query.uid = this.props[0].match.params.uid;
        }


        this.props.socketIOClient.emit('adminFetch', obj);
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('adminFetch', (data) => {
            console.log(data);
            this.props.handleLoader(null);


            this.setState({
                results: data.result,
                count: data.count,
                imageErrors: {}
            });
        });

        this.props.socketIOClient.on('adminUpdate', (data) => {
            console.log(data);
            this.props.handleLoader(null);
            this.fetchItems();
        });

        this.props.socketIOClient.on('adminCopyProduct', (data) => {
            this.fetchItems();

        });

        this.props.socketIOClient.on('adminDelete', (data) => {
            console.log(data);
            this.props.handleLoader(null);
            this.fetchItems();
        });



        this.fetchItems();
    }

    changePage(page) {


        this.setState({
            page: page
        }, () => this.fetchItems())

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }

    handleDeletePrompt(answer, val) {
        // console.log(val);
        if (answer) {
            this.props.socketIOClient.emit('adminDelete', { collection: this.props.collection, _id: val });
            this.props.openDeletePrompt(null, null);
        } else {
            this.props.openDeletePrompt(null, null);
        }
    }

    downloadInfo(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }

    render() {
        console.log(this.state.results)
        var text = 'Some data I want to export';

        var data = new Blob([text], {type: 'text/plain'});

        var url = window.URL.createObjectURL(data);

        document.getElementById('download_link');

        console.log(document.getElementById('download_link'));

        const csvData = [
          ];

        let headers = [
        { label: "First Name", key: "FIRST_NAME" },
        { label: "Last Name", key: "LAST_NAME" },
        { label: "Email", key: "EMAIL" }
        ];
        return (
            
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <div className="panel panel-list">
                    <div>

                        <Container fluid>
                            <Row className="title">
                                <Col lg="2" xs="12">
                                    <h1>{this.props.translate(this.props.title)}</h1>
                                    <h6>{this.props.translate(this.props.subtitle)}</h6>

                                </Col>

                                <Col lg="3">
                                    <div class="input-wrap search-wrap">
                                        <input placeholder={this.props.translate('Pretraži...')} type="text" value={this.state.search} onChange={(e) => {
                                            this.setState({ search: e.target.value }, () => {

                                                this.fetchItems();


                                            });
                                        }} />

                                        <i className="mdi mdi-magnify" />
                                    </div>
                                </Col>

                                <Col lg={{ size: 3, offset: 4 }}>
                                    {this.props[0].location.pathname === '/products' ? <div onClick={() => this.setState({ showOnlyLowStockArticles: !this.state.showOnlyLowStockArticles }, () => this.fetchItems())} className="input-wrap">
                                        <div class={this.state.showOnlyLowStockArticles ? "checkbox checked" : "checkbox"} ></div>
                                        <span>{this.props.translate('Prikaži proizvode koji nisu na stanju')}</span>

                                    </div>

                                        : null}

                                    {this.props[0].location.pathname === '/users' ? <div onClick={() => this.setState({ showOnlySubscribedUsers: !this.state.showOnlySubscribedUsers }, () => this.fetchItems())} className="input-wrap">
                                        <div class={this.state.showOnlySubscribedUsers ? "checkbox checked" : "checkbox"} ></div>
                                        <span>{this.props.translate('Prikaži korisnike prijavljene na newsletter')}</span>

                                    </div>

                                        : null}

                                </Col>

                            </Row>
                        </Container>

                        <div className={this.props.haveImage ? 'table' : 'table table-no-image'}>
                            <table>

                                <tr>
                                    {
                                        this.props.itemFields.map((field, idx) => {
                                            return (
                                                <th onClick={() => {
                                                    if (field.disableSort) {
                                                        return;
                                                    }
                                                    let sort = {};
                                                    console.log(this.state.sort);
                                                    if (this.state.sort) {
                                                        sort[field.name] = this.state.sort[field.name];

                                                    }

                                                    if (!sort[field.name]) {
                                                        sort[field.name] = 1;
                                                    } else {
                                                        sort[field.name] *= -1;
                                                    }
                                                    this.setState({ sort: sort }, this.fetchItems);
                                                }}>{this.props.translate(field.label)} {this.state.sort && this.state.sort[field.name] ? (this.state.sort[field.name] === 1 ? <i className="mdi mdi-arrow-up" /> : <i className="mdi mdi-arrow-down" />) : null} </th>
                                            )
                                        })
                                    }
                                    <th></th>
                                </tr>

                                {

                                    this.state.results && this.state.results.length && this.state.results.map((item, idx) => {
                                        console.log(item)
                                        let csvArray = [];


                                if (this.props[0].location.pathname.indexOf('orders') !== -1) {
                                    let arr = [{FIRST_NAME: 'Naziv proizvoda', LAST_NAME: 'Kolicina', EMAIL: 'Cena'}]
                                    //let obj = {}

                                    if(item.products) {
                                        for(let i = 0; i < item.products.length; i++) {
                                            let obj = {
                                                FIRST_NAME: item.products[i].Name ? item.products[i].Name: '',
                                                LAST_NAME: item.products[i].cartQuantity ? item.products[i].cartQuantity: '',
                                                EMAIL: item.products[i].price ? item.products[i].price: '',
                                            }
                                            arr.push(obj)
                                        }
                                    }
                    

                                     csvArray = [{
                                            FIRST_NAME: item.BillingAddress && item.BillingAddress.FirstName ? item.BillingAddress.FirstName: '',
                                            LAST_NAME: item.BillingAddress && item.BillingAddress.LastName ? item.BillingAddress.LastName: '',
                                            EMAIL: item.BillingAddress && item.BillingAddress.EMail ? item.BillingAddress.EMail: '',
                                            CITY: item.BillingAddress && item.BillingAddress.City ? item.BillingAddress.City: '',
                                            DELIVERY: item.DeliveryMethod && item.DeliveryMethod.name ? item.DeliveryMethod.name: '',
                                            BILING_METHOD: item.BillingMethod ? item.BillingMethod: '',
                                            TOTAL: item.total ? item.total: '',
                                            STATUS: item.Status ? item.Status: '',
                                            
                                        },
                                    ...arr]

                                        console.log(csvArray[0])
                                    }

                                        
                                        return (
                                            <tr key={idx + item._id} className={this.props[0].location.pathname === '/products' && item.StockLevel <= item.StockLevelAlert ? 'stock-alert' : ''}>
                                                {
                                                    this.props.itemFields.map((field, idx1) => {
                                                        let broken = field.name.split('.');
                                                        let value = item;

                                                        for (let i = 0; i < broken.length; i++) {
                                                            if (broken[i][broken[i].length - 1] === ']') {
                                                                let re = /\[(.*)\]/i;
                                                                let index = broken[i].match(re)[1];
                                                                if (value[broken[i].split('[')[0]] && value[broken[i].split('[')[0]][index])
                                                                    value = value[broken[i].split('[')[0]][index];
                                                                else {
                                                                    value = null;
                                                                    break;
                                                                }

                                                            } else {
                                                                if (value[broken[i]]) {
                                                                    value = value[broken[i]];
                                                                } else {
                                                                    value = null;
                                                                    break;
                                                                }
                                                            }
                                                        }


                                                        if (field.type === 'image') {
                                                            return (
                                                                <td key={idx1 + item._id}><img onError={() => {
                                                                    let imageErrors = this.state.imageErrors;
                                                                    imageErrors[value] = true;
                                                                    this.setState({ imageErrors })
                                                                }} src={!this.state.imageErrors[value] ? value ? value : image : image} /></td>
                                                            )
                                                        } else if (field.type === 'text' || field.type === 'number') {
                                                            //console.log(value);
                                                            if (field.allowEdit) {
                                                                return (
                                                                    <td key={idx1 + item._id}><input type={field.type} className="number-input" value={this.state[item._id + "_" + field.name + "_" + idx] ? this.state[item._id + "_" + field.name + "_" + idx] : typeof (this.state[item._id + "_" + field.name + "_" + idx]) === 'string' || typeof (this.state[item._id + "_" + field.name + "_" + idx]) === 'number' ? this.state[item._id + "_" + field.name + "_" + idx] : value} onFocus={() => {
                                                                        let state = { valueName: field.name };
                                                                        state[item._id + "_" + field.name + "_" + idx] = value;
                                                                        this.setState(state);
                                                                    }}
                                                                        onBlur={() => {
                                                                            let updateObj = { collection: this.props.collection, query: { _id: item._id }, data: {} };
                                                                            if (this.props[0].match.params.uid && this.props[0].location.pathname.indexOf('priceList') !== -1) {
                                                                                updateObj.query.uid = this.props[0].match.params.uid;
                                                                            }

                                                                            if (field.type === 'number') {
                                                                                updateObj.data[field.name.replace(/\]/, '').replace(/\[/, '.')] = this.state[item._id + "_" + field.name + "_" + idx] * 1;
                                                                            } else {
                                                                                updateObj.data[field.name.replace(/\]/, '').replace(/\[/, '.')] = this.state[item._id + "_" + field.name + "_" + idx];

                                                                            }
                                                                            this.props.handleLoader(true);
                                                                            this.props.socketIOClient.emit('adminUpdate', updateObj);

                                                                            let state = { valueName: null };
                                                                            //state[field.name + "_" + idx] = null;
                                                                            this.setState(state);

                                                                        }}

                                                                        onChange={(e) => {
                                                                            let state = {};
                                                                            state[item._id + "_" + field.name + "_" + idx] = e.target.value;
                                                                            this.setState(state);
                                                                        }}

                                                                    /></td>
                                                                )
                                                            } else {
                                                                return (
                                                                    <td key={idx1 + item._id}>{value}</td>
                                                                )
                                                            }
                                                        } else if (field.type === 'price') {

                                                            if (field.allowEdit) {
                                                                return (
                                                                    <td key={idx1 + item._id}><input type="number" className="number-input" value={this.state[item._id + "_" + field.name + "_" + idx] ? this.state[item._id + "_" + field.name + "_" + idx] : typeof (this.state[item._id + "_" + field.name + "_" + idx]) === 'string' ? this.state[item._id + "_" + field.name + "_" + idx] : value} onFocus={() => {
                                                                        let state = { valueName: field.name };
                                                                        state[item._id + "_" + field.name + "_" + idx] = value;
                                                                        this.setState(state);
                                                                    }}
                                                                        onBlur={() => {
                                                                            let updateObj = { collection: 'products', query: { _id: item._id }, data: {} };
                                                                            updateObj.data[field.name.replace(/\]/, '').replace(/\[/, '.')] = this.state[item._id + "_" + field.name + "_" + idx] ? this.state[item._id + "_" + field.name + "_" + idx] * 1 : null;
                                                                            this.props.handleLoader(true);
                                                                            this.props.socketIOClient.emit('adminUpdate', updateObj);

                                                                            let state = { valueName: null };
                                                                            //state[field.name + "_" + idx] = null;
                                                                            this.setState(state);

                                                                        }}

                                                                        onChange={(e) => {
                                                                            let state = {};
                                                                            state[item._id + "_" + field.name + "_" + idx] = e.target.value;
                                                                            this.setState(state);
                                                                        }}

                                                                    /></td>
                                                                )
                                                            } else {
                                                                return (
                                                                    <td key={idx1 + item._id}>{value ? parseFloat(value).toFixed(2) : '0.00'}</td>
                                                                )
                                                            }


                                                        } else if (field.type == 'datetime') {
                                                            return (
                                                                <td key={idx1 + item._id}>{moment.unix(value).format(field.format)}</td>
                                                            )
                                                        }
                                                    })
                                                }

                                                <td className="action">
                                                    {this.props[0].location.pathname.indexOf('orders') !== -1 ? <button onClick={() => {

                                                        if (!item.Charged) {
                                                            this.props.socketIOClient.emit('adminUpdate', {
                                                                collection: 'orders', query: { _id: item._id }, data: {
                                                                    Charged: moment().format('DD.MM.YYYY'),
                                                                    Status: 'Plaćeno'
                                                                }
                                                            });
                                                        } else {
                                                            this.props.socketIOClient.emit('adminUpdate', {
                                                                collection: 'orders', query: { _id: item._id }, data: {
                                                                    Charged: null,
                                                                    Status: 'Na čekanju'
                                                                }
                                                            });

                                                        }



                                                    }} className="button" ><i className={item.Charged ? 'mdi mdi-cash-multiple' : 'mdi mdi-cash-multiple disabled'}></i> </button> : null}

                                                    {this.props[0].location.pathname === '/orders' && <button className="button" onClick={() => {

                                                        if (!item.Delivered) {
                                                            this.props.socketIOClient.emit('adminUpdate', {
                                                                collection: 'orders', query: { _id: item._id }, data: {
                                                                    Delivered: moment().format('DD.MM.YYYY'),
                                                                    Status: 'Poslato'
                                                                }
                                                            });
                                                        } else {
                                                            this.props.socketIOClient.emit('adminUpdate', {
                                                                collection: 'orders', query: { _id: item._id }, data: {
                                                                    Delivered: null,
                                                                    Status: 'Na čekanju'
                                                                }
                                                            });

                                                        }



                                                    }}><i className={item.Delivered != null ? "mdi mdi-truck" : "mdi mdi-truck disabled"}></i> </button>}


                                            {this.props[0].location.pathname.indexOf('orders') !== -1 ? <button className="button" onClick={() => {                                                                                                         
                                                                    
                                            }}>

=                                               <CSVLink 
                                                    data={csvArray} 
                                                    headers={[{label: 'Ime', key: 'FIRST_NAME'},{label: 'Prezime', key: 'LAST_NAME'}, {label: 'Mail', key: 'EMAIL'}, {label: 'Dostava', key: 'DELIVERY'}, {label: 'Metoda plaćanja', key: 'BILLING_METHOD'}, {label: 'Ukupno', key: 'TOTAL'}, {label: 'Status', key: 'STATUS'}, {label: 'Proizvodi', key: 'PRODUCTS'}, {label: 'Ime proizvoda', key: 'PRODUCT_NAME'}, {label: 'Količina', key: 'QUANTITY'}, {label: 'Cijena', key: 'PRICE'}]} onClick={() => {
                                                            
                                               }}>Download me</CSVLink>
                                               <button onClick={(filename, text) => {
                                                   var text = csvArray;
                                                   var filename = "hello.html";

                                                   this.downloadInfo(filename, text)
                                                   
                                               }} />
                                              {/*
                                               <a href={`/order/${item._id}`} download>Bam</a>
                                              */}
        
                                               <i className="csv-gen"></i>

                                               <a id="download_link" download="my_exported_file.txt" href="" >Download as Text File</a>

                                               



                                            </button> : null}

                                                    {this.props[0].location.pathname === '/products' && <div> <button className="button" onClick={() => {
                                                        this.props.socketIOClient.emit('adminUpdatePosition', {
                                                            _id: item._id, Position: item.Position - 1, oldPosition: item.Position

                                                        });

                                                    }} >

                                                        <i className="mdi mdi-arrow-up" />



                                                    </button>
                                                        <button className="button" onClick={() => {
                                                            this.props.socketIOClient.emit('adminUpdatePosition', {
                                                                _id: item._id, Position: item.Position + 1, oldPosition: item.Position

                                                            });



                                                        }} >

                                                            <i className="mdi mdi-arrow-down" />

                                                        </button>
                                                    </div>
                                                    }


                                                    {this.props[0].location.pathname === '/products' && <button className="button" onClick={() => {
                                                        this.props.socketIOClient.emit('adminCopyProduct', {
                                                            _id: item._id
                                                        });

                                                    }} ><i className="mdi  mdi-content-copy"></i> </button>}

                                                    {this.props[0].location.pathname === '/users' && <Link to={`/user-orders/${item._id}`} className="button" ><i className="mdi  mdi-package-variant-closed"></i> </Link>}


                                                    {this.props[0].location.pathname.indexOf('user-orders') !== -1 ?
                                                        <Link to={`/orders/${item._id}`} className="button" ><i className="mdi mdi-pencil"></i> </Link> :
                                                        (this.props[0].location.pathname !== '/contacts' && this.props[0].location.pathname.indexOf('priceList') == -1 ?
                                                            <Link to={`${this.props.link}/${item._id}`} className="button" ><i className="mdi mdi-pencil"></i> </Link>
                                                            :
                                                            null
                                                        )}

                                                    {this.props[0].location.pathname.indexOf('orders') !== -1 ? <button onClick={() => this.props.openDeletePrompt(item._id, (answer, val) => {

                                                        if (answer) {
                                                            this.props.socketIOClient.emit('adminUpdate', {
                                                                collection: 'orders', query: { _id: val }, data: {
                                                                    IsHidden: true
                                                                }
                                                            });

                                                            this.props.openDeletePrompt(null, null);
                                                        } else {
                                                            this.props.openDeletePrompt(null, null);
                                                        }



                                                    })} className="button" ><i className="mdi mdi-delete"></i> </button> : null}

                                                    {!this.props.disableDelete && <button className="button" onClick={() => this.props.openDeletePrompt(item._id, this.handleDeletePrompt)}><i className="mdi mdi-delete"></i> </button>}
                                                </td>



                                            </tr>


                                        )
                                    }
                                    )}

                            </table>

                        </div>

                        <Container fluid>
                            <Row>
                                <Col lg="12">
                                    <ReactPaginate
                                        previousLabel={''}
                                        nextLabel={''}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.count / 20}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={(page) => { this.changePage(page.selected) }}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />

                                </Col>
                            </Row>
                        </Container>


                    </div>

                </div>


            </div>
        );
    }
}

export default PageWithLayout(ListPage);