import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { Line, Polar } from 'react-chartjs-2';
import moment from 'moment';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';


class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeClients: 0,
      last7DaysVisits: {
        labels: ['Today', 'Yesterday', '', '', '', '', ''],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      },
      last24HoursVisits: {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      },
      productVisits: []
    };
  }


  componentDidMount() {
    //this.props.handleLoader(true);
    if (this.props.socketIOClient && this.props.uData) {
      this.registerSocketIOEvents();
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.socketIOClient && this.props.uData) {
      this.registerSocketIOEvents();
    }
  }


  registerSocketIOEvents() {
    if (this.state._registeredEvents)
      return;

    this.setState({
      _registeredEvents: true
    });



    this.props.socketIOClient.on('adminFetch', (data) => {
      console.log(data);
      this.props.handleLoader(null);
      this.setState({
        results: data.result,
        count: data.count,
        imageErrors: {}
      });
    });

    this.props.socketIOClient.on('adminActiveClients', (data) => {
      console.log(data);
      this.setState({
        activeClients: data.count
      })
    });

    this.props.socketIOClient.on('adminStatistic', (data) => {
      console.log(data);

      let last7DaysVisits = {
        labels: ['Today', 'Yesterday', moment.unix(data.days[2].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[3].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[4].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[5].timestamp).format('DD.MM.YYYY'), moment.unix(data.days[6].timestamp).format('DD.MM.YYYY')].reverse(),
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      };



      for (let i = 0; i < data.days.length; i++) {
        last7DaysVisits.datasets[0].data.push(data.days[i].count);
      }

      last7DaysVisits.datasets[0].data = last7DaysVisits.datasets[0].data.reverse();



      let last24HoursVisits = {
        labels: [],
        datasets: [
          {
            fill: true,
            lineTension: 0.45,
            backgroundColor: 'rgba(146,44,136,0.2)',
            borderColor: '#922c88',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(146,44,136,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255,255,255)',
            pointHoverBorderColor: 'rgba(146,44,136,0.8)',
            pointHoverBorderWidth: 7,
            pointRadius: 6,
            pointHitRadius: 10,
            data: []
          }
        ],
      };


      for (let i = 0; i < data.hours.length; i++) {
        last24HoursVisits.datasets[0].data.push(data.hours[i].count);
        last24HoursVisits.labels.push(moment.unix(data.hours[i].timestamp).format('HH:mm'));
      }



      data.last7DaysVisits = last7DaysVisits;
      data.last24HoursVisits = last24HoursVisits;

      this.setState(data);
    });

    this.props.socketIOClient.emit('adminActiveClients', {});
    this.props.socketIOClient.emit('adminStatistic', {});

  }


  componentWillUnmount() {
    if (!this.props.socketIOClient) return;
    this.props.socketIOClient.removeAllListeners("adminFetch");
  }


  render() {

    return (
      <div className="content">
        {

          (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

        }

        <div className="panel ">
          <div className="panel-title">
            <h1>{this.props.translate('Dashboard')}</h1>
            <h6>{this.props.translate('Statistika websajta')}</h6>

          </div>


        </div>


        <Container fluid>

          <Row>
            <Col lg="6">
              <Container>
                <Row>
                  <Col lg="3" xs="6">
                    <div className="box">
                      <div>
                        <i className="mdi mdi-account-network"></i>
                        <h6>{this.props.translate('Trenutna poseta')}</h6>
                        <p>{this.state.activeClients}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg="3" xs="6">

                    <div className="box">
                      <div>
                        <i className="mdi mdi-package-variant-closed"></i>
                        <h6>{this.props.translate('Narudžbe')}</h6>
                        <p>{this.state.orders}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg="3" xs="6">

                    <div className="box">
                      <div>
                        <i className="mdi mdi-package-variant"></i>
                        <h6>{this.props.translate('Proizvodi')}</h6>
                        <p>{this.state.products}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg="3" xs="6">


                    <div className="box">
                      <div>
                        <i className="mdi mdi-account"></i>
                        <h6>{this.props.translate('Korisnici')}</h6>
                        <p>{this.state.users}</p>
                      </div>
                    </div>
                  </Col>

                  <Col lg="12">

                    <div className="chartjs-panel">
                      <div>
                        <div className="container-title">
                          <h3>{this.props.translate('Posete websajta')}</h3>
                          <h6>{this.props.translate('Poslednjih 7 dana')}</h6>

                        </div>


                        <Line data={this.state.last7DaysVisits} options={{
                          responsive: true,
                          legend: { display: false },
                          scales: {
                            xAxes: [{
                              gridLines: {
                                display: false
                              }
                            }],
                            yAxes: [{
                              gridLines: {
                                drawBorder: false
                              }
                            }]

                          },

                        }} />


                      </div>
                    </div>
                  </Col>


                  <Col lg="12">

                    <div className="chartjs-panel">
                      <div>
                        <div className="container-title">
                          <h3>{this.props.translate('Posete websajta')}</h3>
                          <h6>{this.props.translate('Danas')}</h6>

                        </div>


                        <Line data={this.state.last24HoursVisits} options={{
                          responsive: true,
                          legend: { display: false },
                          scales: {
                            xAxes: [{
                              gridLines: {
                                display: false
                              }
                            }],
                            yAxes: [{
                              gridLines: {
                                drawBorder: false
                              }
                            }]

                          },

                        }} />


                      </div>
                    </div>
                  </Col>

                </Row>
              </Container>
            </Col>
            <Col lg="6" xs="12">
              <div className="container-title">
                <h3>{this.props.translate('Poslednje pregledano')}</h3>
              </div>

              <div className='table'>
                <table>

                  <tr>
                    <th></th>
                    <th>{this.props.translate('Alias')}</th>
                    <th>{this.props.translate('Name')}</th>

                    <th></th>
                  </tr>

                  {
                    this.state.productVisits.map((item, idx) => {
                      if (item)
                      return (
                        <tr key={idx}>
                          <td><img src={item.Images && item.Images[0]} /></td>
                          <td>{item.Alias} </td>
                          <td>{item.Name ? item.Name: ''} </td>

                        </tr>
                      )
                    })
                  }

                </table>

              </div>
            </Col>
          </Row>
        </Container>

      </div>



    );
  }
}

export default PageWithLayout(HomePage);